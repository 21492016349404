body {
  margin: 0;
  background-color: #ccc;
  font-family: Segoe UI, Tahoma, sans-serif;
}

.container_document {
  width: fit-content;
  margin: 0 0;
}

.container_mini_document {
  width: fit-content;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.container_document .react-pdf__Page {
  margin: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.container_mini_document .react-pdf__Page {
  cursor: pointer;
  margin: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.stretchForm .ant-form-item-label {
  padding: 0;
}
