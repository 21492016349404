@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 425px) {
  .sider {
    position: absolute !important;
    height: 100vh;
    z-index: 999;
  }
}

.datepicker button{
  background-color: #1677ff;
}

.leaflet-container {
  height: 800px
}

.bg-sky {
  background-color: 'sky';
}